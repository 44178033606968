<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Import Date</th>
                        <th class="">Import Ref No</th>
                        <th class="">Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in journals" :key="i">
                        <td>
                            <span class="fw-bold">{{ item.date }}</span>
                        </td>
                        <td class="">{{ item.import_ref_no }}</td>
                        <td class="">{{ item.status }}</td>
                        <td>
                            <button
                                @click="$emit('onDelete', item.id)"
                                type="button" 
                                class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
                                <img width="20" src="/app-assets/images/icons/trash.svg" alt="">
                            </button>
                            <button
                                v-if="item.status != 'completed'"
                                @click="gotoEditPage(item.id)"
                                type="button" 
                                class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
                                <img width="16" src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
                            </button>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            <p v-if="!journals.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'JournalImportTable',

    props: ['journals'],

    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },
    methods: {
        handleDelete() {
            //to do
        },

        gotoEditPage(id) {
            this.$router.push({name: 'journal-import-edit', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    uploadLogId: id
                },
                query: {
                    start: this.$route.query.start,
                    end:  this.$route.query.end
                }
            
            })
        }
    }
}
</script>
